<template>
  <div class="portal_users">
    <h2>Portal Users</h2>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card title="Menu" class="text-left">
            <b-card-text>
              <ul>
                <li>
                  <strong>
                    <b-link to="support-requests">Support Requests ({{ support_request_count }})</b-link>
                  </strong>
                </li>
                <li>
                  <strong>
                    <b-link to="manage-portal-users">Manage Users</b-link>
                  </strong>
                </li>
              </ul>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      support_request_count: 0,
    }
  },
  mounted() {
    this.fetchSupportRequestCount()
  },
  methods: {
    async fetchSupportRequestCount() {
      const path = process.env.VUE_APP_API_URI + "/support-requests-count"
      try {
        console.log("fetching support request count")
        let response = await this.axios.get(path)
        if (response.data) {
          this.support_request_count = response.data.count
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>
